<template>
	<div class="sz-date-picker-input">
		<v-menu
			v-model="showMenu"
			allow-overflow
			:close-on-content-click="false"
			content-class="sz-date-picker-input__menu-content elevation-0"
			nudge-top="1"
			:nudge-right="nudgeRight"
			offset-y
			min-width="290"
		>
			<template v-slot:activator="{ on }">
				<v-text-field
					v-model="date"
					v-on="on"
					hide-details
					readonly
					:clearable="clearable"
					:label="label"
					:prepend-icon="prependIcon"
				/>
			</template>

			<v-layout row justify-center>
				<v-card class="elevation-4">
					<v-date-picker
						v-model="date"
						no-title
						scrollable
						:min="min"
						:max="max"
						:first-day-of-week="1"
						:locale="locale"
						:header-date-format="formatHeader"
						:day-format="formatDateTable"
					/>
				</v-card>
			</v-layout>
		</v-menu>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

function dateToIso8601Date(date: Date) {
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const pad = (n: number) => (n < 10 ? "0" + n : n);
	return date.getFullYear() + "-" + pad(month) + "-" + pad(day);
}

@Component
export default class SzDatePickerInput extends Vue {
	@Prop({ type: [Date, Object] })
	value!: Date | undefined;

	@Prop()
	label!: string;

	@Prop()
	prependIcon!: string;

	@Prop({ default: false })
	clearable!: boolean;

	@Prop()
	minDate!: Date;

	@Prop()
	maxDate!: Date;

	date = "";
	showMenu = false;

	get min() {
		return this.minDate && dateToIso8601Date(this.minDate);
	}

	get max() {
		return this.maxDate && dateToIso8601Date(this.maxDate);
	}

	get nudgeRight() {
		return this.prependIcon && "33";
	}

	get locale() {
		if (this.$i18n.locale === "no") return "nb";

		return this.$i18n.locale;
	}

	formatDateLocale(date: string, options: Intl.DateTimeFormatOptions): string {
		return new Intl.DateTimeFormat(this.locale, options).format(new Date(date));
	}

	formatHeader(date: string): string {
		if (String(date).split("-")[1]) {
			const newDate = this.formatDateLocale(date, { month: "long", year: "numeric" });

			return newDate[0].toUpperCase() + newDate.slice(1);
		}

		return this.formatDateLocale(date, { year: "numeric" });
	}

	formatDateTable(date: string): string {
		const newDate = this.formatDateLocale(date, { day: "numeric" });

		if (newDate.slice(-1) === ".") {
			return newDate.replace(".", "");
		}

		return newDate;
	}

	@Watch("date")
	onDateChanged() {
		if (!this.date) {
			this.$emit("input", undefined);
			return;
		}
		const value = (this.value && new Date(this.value)) || new Date();
		const newDate = new Date(this.date);
		value.setFullYear(newDate.getFullYear());
		value.setMonth(newDate.getMonth());
		value.setDate(newDate.getDate());
		value.setHours(newDate.getHours(), 0, 0, 0);
		this.$emit("input", value);
	}

	@Watch("value", { immediate: true })
	onValueChanged() {
		if (!(this.value instanceof Date)) return;
		const date = dateToIso8601Date(this.value);
		if (this.date !== date) {
			this.date = date;
		}
	}
}
</script>

<style lang="scss">
.sz-date-picker-input {
	&__menu-content.v-menu__content {
		contain: unset;
		overflow: visible;
	}
}
</style>
