<template>
	<div class="client-entity-details-washlist">
		<div
			class="client-entity-details-washlist__registration"
			v-for="registration in washlistRegistrations"
			:key="registration.id">
			<StatusPill :color="'blue'">{{ registration.date | moment("D. MMMM YYYY - HH:mm") }}</StatusPill>
			<StatusPill v-if="!isDisplay" :color="'orange'">{{ registration.display.name }}</StatusPill>
			<span class="client-entity-details-washlist__registration__signature">{{ registration.signature }}</span>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import http from "@/http";
import StatusPill from "@/components/NoteBoard/NoteStatusPill.vue";

@Component<ClientEntityDetailsWashlist>({
	title: (vm) => ["route.client.entityDetails.navigation.Washlist", vm.entity.name],
	components: { StatusPill },
})
export default class ClientEntityDetailsWashlist extends Vue {
	@Prop({ required: true })
	id!: string;

	washlistRegistrations = [];

	get entity() {
		return this.$store.getters.getEntity(this.id);
	}

	get isDisplay() {
		return this.entity.type === "Display";
	}

	async fetchWashlistRegistrations() {
		const displayIds = this.isDisplay
			? [this.entity.id]
			: this.$store.getters
					.getDescendantsOfEntity(this.entity)
					.filter((descendant: any) => descendant.type === "Display")
					.map((descendant: any) => descendant.id);

		const res = await http.get(`washlist`, { params: { displayIds } });
		if (!res || res.status !== 200) throw new Error(`Failed to load washlist registrations.`);

		this.washlistRegistrations = res.data;
	}

	mounted() {
		this.fetchWashlistRegistrations();
	}
}
</script>

<style scoped>
.client-entity-details-washlist__registration {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 1rem;
	border-radius: 20px;
	border: 1px solid #ebebeb;
	background: #fff;
	padding: 17px 25px;
	box-shadow: var(--shadow);
}

.client-entity-details-washlist__registration + .client-entity-details-washlist__registration {
	margin-top: 0.8rem;
}

.client-entity-details-washlist__registration__signature {
	font-size: 1.2em;
	font-style: italic;
	color: rgb(50, 50, 50);
}
</style>
