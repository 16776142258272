<template>
	<layout-sidenav
		v-if="entity"
		class="entity-details"
		:sidenav-force-icon-slot="true"
		:sidenav-list="[
			// { to: `/entities/${id}/general`, label: $t(`route.client.entityDetails.navigation.General`), icon: 'tune' },
			// { to: `/entities/${id}/settings`, label: $t(`route.client.entityDetails.navigation.Settings`), icon: 'settings' },
			// { to: `/entities/${id}/members`, label: $t(`route.client.entityDetails.navigation.Members`), icon: 'group' },
			// 'divider',
			{ to: `/entities/${id}/notes`, label: $t(`route.client.entityDetails.navigation.Notes`), icon: 'note' },
			{
				to: `/entities/${id}/templates`,
				label: $t(`route.client.entityDetails.navigation.Templates`),
				icon: 'collections',
			},
			enableWashlist && 'divider',
			enableWashlist && {
				to: `/entities/${id}/washlist`,
				label: $t(`route.client.entityDetails.navigation.Washlist`),
				icon: 'cleaning_services',
			},
			{ can: 'admin', type: 'divider' },
			{
				can: 'admin',
				to: `/entities/${id}/ads/small`,
				label: $t(`route.client.entityDetails.navigation.AdsSmall`),
				icon: 'crop_7_5',
			},
			{
				can: 'admin',
				to: `/entities/${id}/ads/large`,
				label: $t(`route.client.entityDetails.navigation.AdsLarge`),
				icon: 'crop_square',
			},
		]">
		<template slot="breadcrumbs">
			<entity-breadcrumbs :entity="entity" />

			<v-btn
				v-if="$scrinz.isDisplay(entity)"
				color="primary"
				class="preview-button"
				small
				:href="previewLink"
				target="_blank">
				{{ $t("route.client.entityDetails.general.info.previewDisplay") }}
			</v-btn>
		</template>

		<!-- <template slot="sidenav-title">
			<v-icon>{{ $scrinz.getIconForEntityType(entity.type) }}</v-icon>
			{{ entity.name }}
		</template> -->

		<template slot="content">
			<router-view />
		</template>
	</layout-sidenav>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

// import http from "@/http";
import store from "@/store";
import { Getter } from "vuex-class";

import { encodeHashid } from "@scrinz/hashids";

import { DISPLAY_URL } from "@/constants";
import LayoutSidenav from "@/layouts/Sidenav.vue";
import EntityBreadcrumbs from "@/components/EntityBreadcrumbs.vue";
import StyretavlaLogo from "@/components/StyretavlaLogo.vue";
import { EntityInterface } from "@scrinz/dtos";
import { SEntitySidebarList } from "@/components/Entity";

@Component<ClientEntityDetails>({
	components: { SEntitySidebarList, StyretavlaLogo, EntityBreadcrumbs, LayoutSidenav },
})
export default class ClientEntityDetails extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity() {
		return store.getters.getEntity(parseInt(this.id, 10));
	}

	get organization() {
		return store.getters.getOrganizationOfEntity(this.entity);
	}

	get enableWashlist() {
		return this.entity?.config?.enableWashlist === true || this.organization?.config?.enableWashlist === true;
	}

	get hashid() {
		return encodeHashid("display", this.entity.id);
	}

	get previewLink() {
		// return `${DISPLAY_URL}/preview/${this.hashid}`;
		return `https://display.staging.styretavla.no/display/${this.hashid}/embed`;
	}

	@Getter("entityContextId")
	entityId!: number;

	@Getter entities!: EntityInterface[];

	sortBy: string = "name";
	sortDirection: string = "ASC";

	toggleSort(by: string) {
		if (by == this.sortBy) {
			if (this.sortDirection == "ASC") {
				this.sortDirection = "DESC";
			} else {
				this.sortDirection = "ASC";
			}
		}

		if (by != this.sortBy) {
			this.sortDirection = "ASC";
			this.sortBy = by;
		}
	}
}
</script>

<style lang="scss">
.app-toolbar .app-toolbar__content .preview-button {
	color: white !important;
}
</style>
